/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../../Context/Context";
import styles from "./Refferal.module.scss";
import { AiTwotoneCopy } from "react-icons/ai";
import nodata from "../../../Assets/nodata.png";
import { authRequestNb, refreshToken } from "../../../Helper/helper";
import CopyToClipboard from "react-copy-to-clipboard";
import swal from "sweetalert";
import DataTable, { createTheme } from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import FilterSelectComponent from "./FilterSelectComponent";
const rData = [];

const Refferal = () => {
  const [data, setData] = useState();
  const [levels, setLevels] = useState([]);
  const [booster, setBooster] = useState();
  const [superBooster, setSuperBooster] = useState();
  const [searchData, setSearchData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [boosterBtext, setBoosterBtext] = useState("Activate");
  const [superBoosterBtext, setSuperBoosterBtext] = useState("Activate");
  const { states } = useContext(Context);

  const dmode = states.dmode;

  const searchHandler = (e) => {
    const _search = e.target.value.toLowerCase();
    const _data = data?.team;
    try {
      if (_search !== "") {
        const result = [];
        _data?.map((value) => {
          delete value["email"];
          let temp;
          for (const val of Object.values(value)) {
            if (val.toLowerCase().search(_search) !== -1) {
              temp = value;
            }
          }
          temp && result.push(temp);
        });
        setSearchData(result);
      }else{
        setSearchData([]);
      }
    } catch (error) {
      if (error) {
        if (_search !== "") {
          const result = [];
          _data?.map((value) => {
            delete value["email"];
            let temp;
            for (const val of Object.values(value)) {
              if (val.toLowerCase().search(`\\${_search}`) !== -1) {
                temp = value;
              }
            }
            temp && result.push(temp);
          });
          setSearchData(result);
        }
      }
    }
  };

  const copiedHandler = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const boosterHandler = async () => {
    setBoosterBtext("Please Wait...");
    const res = await authRequestNb("activate-booster-plan");
     res && swal(res.message);
    if (res.status === 200) {
      setBooster(true);
    }
  };
  const superBoosterHandler = async () => {
    setSuperBoosterBtext("Please Wait...");
    const res = await authRequestNb("activate-super-booster-plan");
     res && swal(res.message);
    if (res.status === 200) {
      setSuperBooster(true);
    }
  };

  useEffect(() => {
    (async () => {
      // window.scrollTo(0, 0)
      const res = await authRequestNb("referral");
      refreshToken(res.refresh_token);
      setData(res.data);
      setBooster(res.data.self_details.booster_staus === "1");
      setSuperBooster(res.data.self_details.super_booster_staus === "1");
      let temp = [];
      for (const _level of Object.keys(res.data.rewards.rewards)) {
        temp.push([_level, res.data.rewards.rewards[_level]]);
      }
      setLevels(temp);
    })();
  }, []);
  const [filterText, setFilterText] = React.useState('');
  const [filterSelectText, setFilterSelectText] = React.useState('');
  const [filterSelectText1, setFilterSelectText1] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

useEffect(()=>{
  console.log("filterSelectText",filterSelectText);
  setFilterText(filterSelectText)
},[filterSelectText])

  const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
        setFilterSelectText('');
			}
		};
		return (
      <>
			<FilterSelectComponent 
      setFilterSelectText={setFilterSelectText}
        
          filterSelectText={filterSelectText} filterText={filterText} setFilterText={setFilterText}/> &nbsp;
			<FilterComponent 
        onFilter={e => setFilterText(e.target.value)} 
        onClear={handleClear} 
        filterText={filterText} 
        filterSelectText={filterSelectText}
        />
      </>
		);
	}, [filterText, filterSelectText, resetPaginationToggle]);
  const [pending, setPending] = React.useState(true);
	const [rows, setRows] = React.useState([]);
  useEffect(() => {
		const timeout = setTimeout(() => {
			setRows(data?.team);
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, [data]);
 const columns =  [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Mobile',
      selector: 'mobile',
      sortable: true,
      cell: (props) => (
        <>
        <a href="#" onClick={() => { clickHandler(props); }} style={{color: dmode ?'#ffffff':'#000000'}}>
          {props.mobile}
        </a>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    },
    {
      name: 'Referral ID',
      selector: 'refferal_id',
      sortable: true,
    },
    {
      name: 'Level',
      selector: 'level',
      sortable: true,
    },
    {
      name: 'Joining Date',
      selector: 'join_date',
      sortable: true,
    },
    {
      name: 'Approval Date',
      selector: 'verified_date',
      sortable: true,
    },
    {
      name: 'Deposit Status',
      selector: 'deposit_status',
      sortable: true,
    },
  ];
  const clickHandler = (state) => {
    console.log("ID ", state);
    window.open("https://wa.me/"+state.mobile, '_blank', 'noopener,noreferrer');
  };
  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  });
  console.log("superBooster",superBooster)
  return (
    <div className={`${styles.reff_l}  ligtMode`}>
      <Navbar />
      <div className= 'stakeMain '>
      <div className= {`${styles.reff} container`}>
        <div className='row align-items-center'>
          <div className="col-sm-7">
          <div className={styles.impn}>
            <h1>Refer & More</h1>
             <div className="row align-items-center">
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>20%</h4>
                <p>of lifetime trading fee like BTC, ETH & BTRL etc</p>
              </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>Refer & Earn Plan
                  <p><a className="btnbox" href="https://btrlexchange.com/backend/plan/btrl-exchange-refer-earn-plan-v3.pdf">View Now</a></p></h4>
              </div>
              </div>
            </div>
            {data && (
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>Booster Plan @500</h4>
                <p>{booster ? (
                  <span className='active pl-2 btnbox'  style={{ backgroundColor: booster ? "#2baa85" : "#7957d4" }}>Active</span>
                ) : (
                  <>
                  {boosterBtext==="Activate"?(
                    <span 
                    type="button"
                    onClick={boosterHandler}
                    style={{ backgroundColor: booster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {boosterBtext}
                  </span>
                  ):(
                    <span 
                    type="button"
                   
                    style={{ backgroundColor: booster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {boosterBtext}
                  </span>
                  )}
                  </>
                )}</p>
              </div>
              </div>
            </div>
             )}
            {data && (
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>Super Booster Plan @5000</h4>
                <p>{superBooster ? (
                  <span className='active pl-2 btnbox'  style={{ backgroundColor: superBooster ? "#2baa85" : "#7957d4" }}>Active</span>
                ) : (
                  <>
                  {superBoosterBtext==="Activate"?(
                    <span 
                    type="button"
                    onClick={superBoosterHandler}
                    style={{ backgroundColor: superBooster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {superBoosterBtext}
                  </span>
                  ):(
                    <span 
                    type="button"
                   
                    style={{ backgroundColor: superBooster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {superBoosterBtext}
                  </span>
                  )}
                  </>
                )}</p>
              </div>
              </div>
            </div>
             )}
            </div>
            
          </div>

          <div className={`${styles.il}  column inviteFull`}>
            <div className="row">
            <div className="col-sm-7">
            <h4>Invite Link</h4>
            <h2 className="flex inviteBox">
              {data?.self_details.url}{" "}
              <button className="flex-center">
                <CopyToClipboard
                  text={data?.self_details.url}
                  onCopy={copiedHandler}
                >
                  <AiTwotoneCopy />
                </CopyToClipboard>
                {copied && <span>Copied!</span>}
              </button>
            </h2>
          </div>
            <div className="col-sm-5 sponerHere">
              <h4>Sponser</h4>
            <p>
              {data?.sponser_details.sponser_id} ({data?.sponser_details.name},{" "}
              <a
                href={`https://wa.me/${data?.sponser_details.mobile}`}
                target="_blank"
                rel="noreferrer"
              >
                <span>{data?.sponser_details.mobile}</span>
              </a>
              )
            </p>
            <p className="s">
              Referral ID - {data?.self_details.refferal_id}
            </p>
            </div>
          </div>
          </div>
        </div>
      <div className="col-sm-5">
        
        <div className="text-center imgmobhide">
          <img src="/5453369_2784129.png" className="img-fluid" />
        </div>
      </div>
      </div>
        </div>

      </div>
        <div className={`${styles.reff} m-0 pb-5`}>
          <div className="container">

        <h4>
          Reward of Referral Level Wise (IN {data?.rewards.reward_currency})
        </h4>
          <div className="row">
            <div className="col-sm-7">

        <div className={`${styles.boxes} `}>
          {levels?.map((value, i) => (
            <div key={i} className={`${styles.box} snPlan `}>
              <p>Level {value[0]}</p>
              <h1>
                {value[1]} {data?.rewards.reward_currency}
              </h1>
            </div>
          ))}
        </div>
      </div>
            <div className="col-sm-5 " data-fil={filterSelectText}>
              <div className="tableDesign">
        <div className={`custableresponsive table-responsive outerBorder specialtable `} >
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Joining</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Today</td>
                <td><b>{data?.joining.todayJoining}</b></td>
              </tr>
              <tr>
                <td>Today Verified</td>
                <td><b>{data?.joining.todayVerifiedJoining}</b></td>
              </tr>
              <tr>
                <td>KYC Completed</td>
                <td><b>{data?.joining.todayComplete}</b></td>
              </tr>
              <tr>
                <td>KYC Pending</td>
                <td><b>{data?.joining.todayPending}</b></td>
              </tr>
              <tr>
                <td>Total</td>
                <td><b>{data?.joining.totalTeam}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
            </div>
            </div>
    </div>

    <DataTable theme={dmode ?"dark":"solarized"} columns={columns} data={rows && rows.length>0? rows.filter(
      item => (
                item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) || 
                item.refferal_id && item.refferal_id.toLowerCase().includes(filterText.toLowerCase()) || 
                item.deposit_status && item.deposit_status.toLowerCase()==filterText.toLowerCase() 
              ),
    ):[]}
    progressPending={pending} pagination subHeader
    subHeaderComponent={subHeaderComponentMemo}
    persistTableHead/> 
    </div>
    </div>

      <Footer />
    </div>
  );
};

export default Refferal;
